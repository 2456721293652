import { SlideFade } from '@chakra-ui/react'
import { useRef } from 'react'
import dynamic from 'next/dynamic'
import Header from '../components/landing/Header'
import MaxWidth from '../components/MaxWidth'
import Title from '../components/landing/Title'
import Subtitle from '../components/landing/Subtitle'
import ActionButtons from '../components/landing/ActionButtons'
import Characteristics from '../components/landing/Characteristics'
import DashboardBg from '../components/landing/DashboardBg'
import SectionTitleSubtitle from '../components/landing/SectionTitleSubtitle'
import FeaturesDescription from '../components/landing/FeaturesDescription'
import FeatureList from '../components/landing/FeatureList'
import PricingList from '../components/landing/PricingList'
import BottomPanel from '../components/landing/BottomPanel'
import Footer from '../components/landing/Footer'
import { Box } from '../components/chakra/Box'
import { Link } from '../components/chakra/Link'
import TestimonialFameWall from '../components/landing/TestimonialFameWall'
import ShareFeature from '../components/landing/ShareFeature'
import FeaturedBlogSection from '../components/landing/FeaturedBlogs'

const InstallPromptNoSSR = dynamic(() => import('../components/dashboard/InstallPrompt'), { ssr: false })

// Landing
function Index() {
  const containerRef = useRef()

  return (
    <Box height="100%" overflowY="auto" ref={containerRef}>
      <Header containerRef={containerRef} />
      <MaxWidth>
        <SlideFade offsetX={0} offsetY={50} in>
          <Title />
          <Subtitle />
          <ActionButtons />
          <Characteristics />
          <ShareFeature />

          <DashboardBg />
        </SlideFade>
        <SectionTitleSubtitle title="Features" id="Features" />
        <FeaturesDescription />
        <FeatureList />
        <SectionTitleSubtitle title="Pricing" subtitle="Cancel anytime" id="Pricing" />
        <PricingList showOffBanner={false} />
        <FeaturedBlogSection />
        <SectionTitleSubtitle
          title="Feedback"
          subtitle="Our users love BackToIt. Here's what they are saying:"
          id="Reviews"
        />
        {/* <TestimonialList /> */}
        <TestimonialFameWall />
      </MaxWidth>
      <BottomPanel />
      <MaxWidth>
        <Footer containerRef={containerRef} />
      </MaxWidth>
      <InstallPromptNoSSR />
      <Link href="/pricing" style={{ display: 'none' }} />
    </Box>
  )
}

export default Index
