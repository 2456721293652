'use client'

import { useMemo } from 'react'
import {
  Box,
  Card,
  CardBody,
  CardFooter,
  Heading,
  Text,
  Stack,
  Image,
  Flex,
  Avatar,
  Link as ChakraLink,
  useColorModeValue,
} from '@chakra-ui/react'
import { CalendarIcon, TimeIcon } from '@chakra-ui/icons'
import NextLink from 'next/link'
import { format } from 'date-fns'
import { Tags } from './Tags'

function BlogCard({ blog: { fields, sys } }) {
  const publishedDate = fields.publishedDate ? new Date(fields.publishedDate) : new Date(sys.createdAt)
  const formattedDate = format(publishedDate, 'MMMM d, yyyy')

  const estimatedReadTime = useMemo(() => {
    if (!fields.content) return '5 min'
    const blockCount = fields.content.content?.length || 0
    const minutes = Math.max(1, Math.ceil(blockCount * 0.5))
    return `${minutes} min`
  }, [fields.content])

  const bgColor = useColorModeValue('gray.800', 'gray.700')
  const textColor = useColorModeValue('gray.100', 'gray.200')
  const mutedColor = useColorModeValue('gray.400', 'gray.500')

  return (
    <Card
      key={sys.id}
      bg={bgColor}
      color={textColor}
      overflow="hidden"
      h="full"
      transition="all 0.3s"
      _hover={{ boxShadow: 'xl' }}
      w={{ base: 'full', md: 'auto' }}
    >
      <Box position="relative" h="48">
        {fields.featuredImage?.fields.file && (
          <Image
            src={`https:${fields.featuredImage.fields.file.url}`}
            alt={fields.title}
            objectFit="cover"
            w="full"
            h="full"
            transition="transform 0.3s"
            _hover={{ transform: 'scale(1.05)' }}
          />
        )}
      </Box>
      <CardBody>
        <Stack spacing={4}>
          <NextLink href={`/blogs/${fields.slug}`} passHref>
            <ChakraLink>
              <Heading size="md" noOfLines={2} _hover={{ color: 'blue.400' }}>
                {fields.title}
              </Heading>
            </ChakraLink>
          </NextLink>
          <Text fontSize="sm" color={mutedColor} noOfLines={3}>
            {fields.shortDescription}
          </Text>
          <Flex justifyContent="space-between" alignItems="center" fontSize="sm" color={mutedColor}>
            <Flex alignItems="center">
              <CalendarIcon mr={2} />
              <Text>{formattedDate}</Text>
            </Flex>
            <Flex alignItems="center">
              <TimeIcon mr={2} />
              <Text>{estimatedReadTime} read</Text>
            </Flex>
          </Flex>
          {fields.author && fields.author.fields && (
            <Flex alignItems="center" gap={2}>
              {fields.author.fields.avatar && fields.author.fields.avatar.fields.file && (
                <Avatar
                  size="sm"
                  name={fields.author.fields.name}
                  src={`https:${fields.author.fields.avatar.fields.file?.url}`}
                />
              )}

              <Text fontSize="sm" fontWeight="medium">
                {fields.author.fields.name}
              </Text>
            </Flex>
          )}
          <Tags categories={fields.categories} />
        </Stack>
      </CardBody>
      <CardFooter>
        <NextLink href={`/blogs/${fields.slug}`} passHref>
          <ChakraLink color="blue.400" fontWeight="medium" _hover={{ color: 'blue.300' }}>
            Read more →
          </ChakraLink>
        </NextLink>
      </CardFooter>
    </Card>
  )
}

export default BlogCard
