/* eslint-disable no-nested-ternary */

'use client'

import { useEffect, useMemo, useState } from 'react'
import { Box, Button, Heading, Flex, useColorModeValue } from '@chakra-ui/react'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import useEmblaCarousel from 'embla-carousel-react'
import BlogCard from '../blog/BlogCard'
import CardSkeletonList from '../blog/CardSkeletonList'
import { Link } from '../chakra/Link'
import { useContentful } from '../../utils/hooks/useContentful'

export default function FeaturedBlogs() {
  // console.log(loading, error, data)
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true })
  const [canScrollPrev, setCanScrollPrev] = useState(false)
  const [canScrollNext, setCanScrollNext] = useState(true)

  const bgColor = useColorModeValue('gray.100', 'gray.900')
  const buttonBgColor = useColorModeValue('white', 'gray.800')
  const buttonColor = useColorModeValue('gray.800', 'white')

  const query = useMemo(
    () => ({
      limit: 1,
      include: 10,
    }),
    []
  )
  const { data, loading, error } = useContentful('pageLandingBacktoIt', query)

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on('select', () => {
        setCanScrollPrev(emblaApi.canScrollPrev())
        setCanScrollNext(emblaApi.canScrollNext())
      })
    }
  }, [emblaApi])

  const featuredBlogs = useMemo(() => {
    if (loading || error) return []

    return data?.fields.featuredBlogPosts || []
  }, [loading, data])

  console.log(featuredBlogs)

  return (
    <Box id="blogs" position="relative" mb={12} bg={bgColor} py={7}>
      <Box maxWidth="container.xl" mx="auto" px={4}>
        <Heading as="h2" size="2xl" mb={4}>
          Featured Blogs
        </Heading>
        <Box overflow="hidden" ref={emblaRef}>
          <Flex>
            {loading || error ? (
              <CardSkeletonList count={4} />
            ) : featuredBlogs ? (
              featuredBlogs.map(blog => (
                <Box key={blog.sys.id} flexShrink={0} width={{ base: '100%', md: '50%', lg: '33.33%' }} pr={4}>
                  <BlogCard blog={blog} />
                </Box>
              ))
            ) : null}
          </Flex>
        </Box>
        <Button
          variant="outline"
          position="absolute"
          top="50%"
          left={4}
          transform="translateY(-50%)"
          onClick={() => emblaApi?.scrollPrev()}
          isDisabled={!canScrollPrev}
          bg={buttonBgColor}
          color={buttonColor}
        >
          <ChevronLeftIcon />
        </Button>
        <Button
          variant="outline"
          position="absolute"
          top="50%"
          right={4}
          transform="translateY(-50%)"
          onClick={() => emblaApi?.scrollNext()}
          isDisabled={!canScrollNext}
          bg={buttonBgColor}
          color={buttonColor}
        >
          <ChevronRightIcon />
        </Button>
        <Flex justify="center" mt={6}>
          <Link href="/blogs" passHref>
            <Button as="a" variant="solid" size="lg" colorScheme="blue">
              Read more blogs
            </Button>
          </Link>
        </Flex>
      </Box>
    </Box>
  )
}
