import { Box, Skeleton, SkeletonText } from '@chakra-ui/react'
import { uniqueId } from 'lodash'

function CardSkeletonList({ count }) {
  return (
    <>
      {Array.from({ length: count }).map(() => (
        <Box key={uniqueId()} width="300px" pr={4} borderRadius={4} overflow="hidden">
          <Skeleton height="200px" mb={4} />
          <SkeletonText mt="4" noOfLines={3} spacing="4" />
        </Box>
      ))}
    </>
  )
}

export default CardSkeletonList
