/* eslint-disable no-shadow */
// create a complex custom hook to fetch data from Contentful with the help of the Contentful SDK
import { useEffect, useMemo, useState } from 'react'
import debounce from 'lodash/debounce'
import { getContentfulClient } from '../../lib/contentful'

const client = getContentfulClient()

export const useContentful = (contentType, query = {}) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [total, setTotal] = useState(0)

  const fetchData = useMemo(
    () =>
      debounce(async (contentType, query) => {
        setLoading(true)
        setError(null)
        try {
          const response = await client.getEntries({
            content_type: contentType,

            ...query,
          })

          setData(response.items)
          setTotal(response.total)
        } catch (error) {
          setError(error)
        } finally {
          setLoading(false)
        }
      }, 500),
    []
  )

  useEffect(() => {
    fetchData(contentType, query)

    return () => {
      fetchData.cancel()
    }
  }, [contentType, query, fetchData])

  if (query?.limit === 1) return { data: data[0], loading, error }
  return { data, loading, error, total }
}
