import { Wrap, WrapItem, Tag } from '@chakra-ui/react'

export function Tags({ categories }) {
  if (!categories || categories.length === 0) return null

  return (
    <Wrap spacing={2}>
      {categories.map(category => (
        <WrapItem key={category.sys.id}>
          <Tag size="sm" colorScheme="blue" borderRadius="full">
            #{category.fields.name}
          </Tag>
        </WrapItem>
      ))}
    </Wrap>
  )
}
